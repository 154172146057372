<template>
    <div class="layout-content">
        <div class="tab-div card">
            <div class="main-container">
                <h2>Advertising</h2>

                <h6 class="title">Partner:</h6>
                <div class="row">
                    <Dropdown :loading="partnersLoading" :options="partners" optionLabel="name" v-model="selectedPartner" placeholder="Select a partner"/>
                    <Button class="green-button__secondary" label="Add a new partner" @click="addNewPartner" />
                </div>

                <h6 class="title">Campaign:</h6>
                <div class="row">
                    <Dropdown :loading="campaignsLoading" :disabled="!selectedPartner" :options="campaigns" optionLabel="name" v-model="selectedCampaign" placeholder="Select a campaign"/>
                    <Button :disabled="!selectedPartner" class="green-button__secondary" label="Add a new campaign" @click="addNewCampaign" />
                </div>
                <div v-if="selectedCampaign" class="details-column">
                    <span>Start Date: {{ getFormattedTime(selectedCampaign?.startDate) }}</span>
                    <span>End Date: {{ getFormattedTime(selectedCampaign?.endDate) }}</span>
                </div>

                <h6 class="title">Ad:</h6>
                <div class="row">
                    <Dropdown :loading="adsLoading" :disabled="!selectedPartner || !selectedCampaign" :options="ads" optionLabel="ad.name" v-model="selectedAd" placeholder="Select an ad"/>
                    <Button :disabled="!selectedPartner || !selectedCampaign" class="green-button__secondary" label="Add a new ad" @click="addNewAd" />
                </div>
                <div v-if="selectedAd" class="details-column">
                    <span>Start Date: {{ getFormattedTime(selectedAd?.startDate) }}</span>
                    <span>End Date: {{ getFormattedTime(selectedAd?.endDate) }}</span>
                    <span>Target URL: {{ selectedAd?.baseUrl }}</span>
                     <Button v-if="!adHasEnded(selectedAd?.endDate)" class="green-button__secondary delete-ad-button" icon="pi pi-stop-circle" @click="endCampaignAd" v-tooltip.bottom="'Stop this campaign ad from running anymore.'"/>
                </div>

                <h6 class="title">Links:</h6>
                <Button :disabled="!selectedPartner || !selectedCampaign || !selectedAd" class="green-button__secondary" label="View links" @click="generateLinks" />
  
                <LoadingSpinner :showLoading="linksLoading" />
                <table class="links-table" v-if="links">
                    <tr class="link-row">
                        <th class="dist-channel-column">Media</th>
                        <th class="url-column">Url</th>
                        <th class="click-column">#Clicks</th>
                        <th class="actions-column">Action</th>
                    </tr>
                    <tr class="link-row" v-for="link in links" :key="link.distributionId">
                        <td class="dist-channel-column">{{getAdDistributionChannelLabel(link.distributionId)}}</td>
                        <td class="url-column">{{link.proxyUrl}}</td>
                        <td class="click-column">{{link.clicks}}</td>
                        <td class="actions-column">
                            <Button class="green-button__secondary" label="Copy" @click="copyToClipboard(link.proxyUrl)" />
                        </td>
                    </tr>
                </table>
                
            </div>
        </div>
    </div>

    <AddNewPartnerModal ref="addNewPartnerModal" />
    <AddNewCampaignModal ref="addNewCampaignModal" />
    <AddNewAdModal ref="addNewAdModal" />
     <ConfirmModal headerText="Stop Ad" ref="confirmEndAdModal" @confirmed="onEndAdConfirmed">
        <template #content>
            <div class="confirm-container"> Are you sure you want to stop this Campaign Ad?</div>
        </template>
    </ConfirmModal>
</template>

<script>

import AddNewPartnerModal from '../components/advertising/AddNewPartnerModal.vue';
import AddNewCampaignModal from '../components/advertising/AddNewCampaignModal.vue';
import AddNewAdModal from '../components/advertising/AddNewAdModal.vue';
import LoadingSpinner from '../components/common/LoadingSpinner.vue';
import Dropdown from 'primevue/dropdown';
import AdService from '../service/AdService';

import EventBus from '../event-bus';
import TimeUtils from '../utilities/TimeUtils';
import ConfirmModal from '../components/modal/ConfirmModal.vue';

const AD_DISTRIBUTION_CHANNEL_LABELS = {
    1:'Instagram',
    2:'Facebook',
    3:'Twitter',
    4:'Linkedin',
    5:'Youtube',
    6:'Snapchat',
    7:'Tiktok',
    8:'Invrs',
}

export default {
    name: 'Advertising',

    components: {
    LoadingSpinner,
    Dropdown,
    AddNewPartnerModal,
    AddNewCampaignModal,
    AddNewAdModal,
    ConfirmModal
},

    data() {
        return {
            partners: [],
            partnersLoading: false,
            selectedPartner: null,

            campaigns: [],
            campaignsLoading: false,
            selectedCampaign: null,

            ads: [],
            adsLoading: false,
            selectedAd: null,

            links: null,
            linksLoading: false,
            postsLoading: false,
        }
    },

    mounted() {
        //this.init();
    },
    activated(){
        this.init();
    },

    watch: {
        // eslint-disable-next-line no-unused-vars
        selectedPartner(newVal, oldVal){
            if(newVal) {
                this.refreshCampaigns();
            }
        },

        // eslint-disable-next-line no-unused-vars
        selectedCampaign(newVal, oldVal){
            if(newVal) {
                this.refreshAds();
            }
        },

        // eslint-disable-next-line no-unused-vars
        selectedAd(newVal, oldVal){
            this.links = null;
        }
    },

    computed: {

    },

    methods: {

        adHasEnded(stopDate) {
            return TimeUtils.isServerTimeSameOrBeforeNow(stopDate, "YYYY/MM/DD hh:mm:ss");
        },

        init() {
            this.refreshPartners();

            EventBus.off("refresh-ad-partners");
            EventBus.on("refresh-ad-partners", this.refreshPartners);
            EventBus.off("refresh-ad-campaigns");
            EventBus.on("refresh-ad-campaigns", this.refreshCampaigns);
            EventBus.off("refresh-ad-ads");
            EventBus.on("refresh-ad-ads", this.refreshAds);
        },

        getFormattedTime(date) {
            return TimeUtils.getFormattedTime(new Date(date), "YYYY/MM/DD");
        },

        getAdDistributionChannelLabel(id) {
            return AD_DISTRIBUTION_CHANNEL_LABELS[id];
        },
        
        copyToClipboard(url) {
            try {
				if (navigator.clipboard) {
					navigator.clipboard.writeText(url);
				}
				this.$toast.add({ severity: 'success', summary: 'Linked copied!', life: 2000, group: 'center' });
			} catch (err) {
				console.debug('Oops, unable to copy'+err);
			}
        },

        refreshPartners() {
            this.partnersLoading = true;
            this.partners = [];
            this.selectedPartner = null;
            AdService.getAdPartners().then(resp => {
                this.partnersLoading = false;
                // console.log("getPartners", resp);
                if(resp?.data?.status == 'success'){
                    this.partners = resp?.data?.partners;
                }

                this.campaigns = [];
                this.selectedCampaign = null;
                this.ads = [];
                this.selectedAd = null;
            });
        },

        refreshCampaigns() {
            this.campaignsLoading = true;
            this.campaigns = [];
            this.selectedCampaign = null;
            AdService.getAdCampaigns(this.selectedPartner.partnerId).then(resp => {
                this.campaignsLoading = false;
                // console.log("getCampaigns", resp);
                if(resp?.data?.status == 'success'){
                    this.campaigns = resp?.data?.campaigns;
                }
                this.ads = [];
                this.selectedAd = null;
            });
        },

        refreshAds() {
            this.adsLoading = true;
            this.ads = [];
            this.selectedAd = null;
            AdService.getCampaignAds(this.selectedCampaign.campaignId).then(resp => {
                this.adsLoading = false;
                // console.log("getCampaignAds", resp);
                if(resp?.data?.status == 'success'){
                    this.ads = resp?.data?.ads;
                }
            });
        },

        addNewPartner() {
            this.$refs.addNewPartnerModal.open();
        },

        addNewCampaign() {
            this.$refs.addNewCampaignModal.open(this.selectedPartner);
        },

        addNewAd() {
            this.$refs.addNewAdModal.open(this.selectedCampaign);
        },

        endCampaignAd() {
            this.$refs.confirmEndAdModal.open();
            
        },
        onEndAdConfirmed(){
            AdService.endCampaignAd(this.selectedCampaign.campaignId, this.selectedAd.adId).then(resp => {
                if( resp.data.status == 'success'){
                    this.$toast.add({ severity: 'success', summary: 'Campaign Ad ended!', life: 4000, group: 'center' });
                    this.refreshAds();
                }
                else {
                    console.log("error deleting ad ", resp.data);
                    this.$toast.add({ severity: 'error', summary: 'Unable to end Campaign Ad.',life: 4000, group: 'center' });
                }
            }).catch(err => {
                console.log("error deleting ad ", err);
                this.$toast.add({ severity: 'error', summary: 'Unable to end Campaign Ad.',life: 4000, group: 'center' });
            })
        }, 
        generateLinks() {
            this.linksLoading = true;
            AdService.getCampaignAdLinks(this.selectedCampaign.campaignId, this.selectedAd.adId).then(resp => {
                // console.log("getCampaignAdLinks", resp);
                this.linksLoading = false;
                if(resp?.data?.status == 'success'){
                    this.links = resp?.data?.links;
                    console.log("links: ", this.links)
                }
            });
        },
    },
}

</script>

<style scoped>
.confirm-container {
   

    margin: 12px;
    display: flex;
    flex-direction: column;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
}
.delete-ad-button{
    width: 30px;
}

.tab-div {
    margin-top: 16px;
    margin-bottom: 16px;
    padding: 16px;
}

.main-container {
    display: flex;
    flex-direction: column;
}

.title {
    margin: 8px 0 8px 0;
}

.details-column {
    display: flex;
    flex-direction: column;
    margin-top: 8px;
    gap: 8px;
}

.row {
    display: flex;
}

.row .p-button {
    flex-grow: 1;
}
.row .p-dropdown {
    width: 650px;
    margin-right: 16px;
}

.links-table {
    display: flex;
    flex-direction: column;
    margin-top: 8px;
    width: 100%;
}

.link-row {
    display: flex;
    margin-bottom: 8px;
}

.dist-channel-column {
    min-width: 80px;
    width: 10%;
    justify-content: center;
    text-align: center;
}
.actions-column {
    min-width: 80px;
    display: flex;
    justify-content: center;
    flex-grow: 1;
}

.click-column {
    width: 10%;
    justify-content: center;
    text-align: center;
    flex-grow: 1;
}

.url-column {
    width: 50%;
    word-break: break-all;
    /* flex-grow: 1; */
    /* overflow: hidden; */
    /* text-overflow: ellipsis; */
}

</style>