<template>
    <Dialog header="Add New Campaign" v-model:visible="display" :modal="true" :closable="true"
        class='add-new-ad-modal'>

        <div>
            <h6>Name:</h6>
            <InputText id='nameInput' type="text" v-model='name' :maxlength="100" placeholder="Enter Name" />
            <h6>Dates:</h6>
            <Calendar dateFormat="yy/mm/dd" showIcon v-model="dateRange" :minDate="minDate"
                selectionMode="range" :manualInput="false" placeholder="Select dates"/>
        </div>
        
        <template #footer>
            <Button label="Create" class="p-button-raised p-button-rounded" @click='submit' :disabled="!isDataEntered" />
        </template>
    </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import Calendar from 'primevue/calendar';

import AdService from '../../service/AdService';
import EventBus from '../../event-bus';

export default {
    name: 'AddNewPartnerModal',
    data() {
        return {
            display: false,
            partner: null,

            name: "",

            dateRange: null,
            minDate: null,
        }
    },

    components: {
    Dialog,
    InputText,
    Calendar
},

    computed: {
        isDataEntered() {
            return (this.name && this.name.length > 0)
                && (this.dateRange && this.dateRange[0] && this.dateRange[1]);
        }
    },

    methods: {
        open(partner) {
            this.display = true;
            this.minDate = new Date();
            this.name = "";
            this.partner = partner;
        },

        submit() {
            this.display = false;
            if (this.isDataEntered) {
                AdService.createAdPartnerCampaign(this.partner.partnerId, this.name, this.dateRange[0], this.dateRange[1]).then(resp => {
                    if (resp.data.status == 'success') {
                        EventBus.emit("refresh-ad-campaigns");
                        this.$toast.add({ severity: 'success', summary: 'Created Ad Campaign!', life: 2000, group: 'center' });
                    }
                });
            }
        }
    },
}
</script>

<style>
.add-new-ad-modal {
    font-family: "Trebuchet MS", "Verdana";
    box-shadow: 0px 24px 38px rgba(0, 0, 0, 0.14), 0px 9px 46px rgba(0, 0, 0, 0.12), 0px 11px 15px rgba(0, 0, 0, 0.2);
}

@media (max-width: 760px) {
    .add-new-ad-modal .p-dialog-header {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    .add-new-ad-modal .p-dialog-content {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .add-new-ad-modal .p-dialog-footer {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
}
</style>
<style scoped>
.p-inputtext {
    width: 100%;
}
</style>