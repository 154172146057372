<template>
    <Dialog header="Add New Partner" ref='addNewAdModal' v-model:visible="display" :modal="true" :closable="true" class='add-new-ad-modal'>

        <div>
            <h6>Name:</h6>
            <InputText id='nameInput' type="text" v-model='name' :maxlength="100" placeholder="Enter Name" />
        </div>

        <template #footer>
            <Button label="Create" class="p-button-raised p-button-rounded" @click='submit' :disabled="!isNameEntered" />
        </template>
    </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import AdService from '../../service/AdService';
import EventBus from '../../event-bus';

export default {
    name: 'AddNewPartnerModal',
    data() {
        return {
            display: false,
            name: "",
        }
    },

    components: {
        Dialog,
        InputText
    },

    computed: {
        isNameEntered(){
            return this.name && this.name.length > 0;
        }
    },

    methods: {
        open() {
            this.display = true;
            this.name = "";
        },

        submit() {
            this.display = false;
            if(this.isNameEntered){
                AdService.createAdPartner(this.name).then(resp =>{
                    if(resp.data.status == 'success'){
                        EventBus.emit("refresh-ad-partners");
                        this.$toast.add({ severity: 'success', summary: 'Created Ad Partner!', life: 2000, group: 'center' });
                    }
                });
            }
        }
    },
}
</script>

<style>
.add-new-ad-modal {
    font-family: "Trebuchet MS", "Verdana";
    box-shadow: 0px 24px 38px rgba(0, 0, 0, 0.14), 0px 9px 46px rgba(0, 0, 0, 0.12), 0px 11px 15px rgba(0, 0, 0, 0.2);
}

@media (max-width: 760px) {
    .add-new-ad-modal .p-dialog-header {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    .add-new-ad-modal .p-dialog-content {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .add-new-ad-modal .p-dialog-footer {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
}
</style>
<style scoped>

</style>